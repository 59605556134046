<template>
  <div class="appeal_detail">
    <div class="content">
      <div class="item">
        <div class="label">服务订单号：</div>
        <div class="value">{{detail.serverNumber}}</div>
      </div>
      <div class="item">
        <div class="label">商家名称：</div>
        <div class="value">{{detail.companyName}}</div>
      </div>
      <div class="item">
        <div class="label">客服电话：</div>
        <div class="value">{{detail.companyTel}}</div>
      </div>
      <div class="item">
        <div class="label">用户姓名：</div>
        <div class="value">{{detail.userName}}</div>
      </div>
      <div class="item">
        <div class="label">用户手机号码：</div>
        <div class="value">{{detail.telNumber}}</div>
      </div>
      <div class="item">
        <div class="label">用户手机号码：</div>
        <div class="value">{{detail.telNumber}}</div>
      </div>
      <div class="item">
        <div class="label">服务地址：</div>
        <div class="value">{{detail.provinceName+detail.cityName+detail.countyName+detail.detailInfo}}</div>
      </div>
      <div class="item">
        <div class="label">结算金额(元)：</div>
        <div class="value">{{detail.amount}}</div>
      </div>
      <div class="item">
        <div class="label">服务人员：</div>
        <div class="value">{{detail.serviceStaffName}}</div>
      </div>
      <div class="item">
        <div class="label">下单时间：</div>
        <div class="value">{{detail.createTime}}</div>
      </div>
      <div class="item">
        <div class="label">状态：</div>
        <div class="value">
          {{statusStrList[detail.status]}}
          <span v-if="detail.status === 5">用户操作</span>
          <span v-if="detail.status === 6">平台操作</span>
        </div>
      </div>
      <div class="item">
        <div class="label">申诉类型：</div>
        <div class="value">{{detail.type}}</div>
      </div>
      <div class="item">
        <div class="label">图片：</div>
        <div v-if="fileList.length>0">
          <img class="image" @click="openImgView(fileList)"  v-for="(src,i) in fileList" :key="i" :src="src" alt="">
        </div>
        <div v-else>无</div>
      </div>
    </div>

    <div class="steps">
      <div class="steps_title">申诉进度</div>
      <el-steps :active="detail.recordList?.length">
        <el-step v-for="(item, index) in detail.recordList" :key="index" >
          <template slot="title">
            <div class="status">
              <div :class="index===detail.recordList?.length-1 ? 'active' : ''">{{ statusStrList[item.status] }}</div>
              <span v-if="item.status === 5">用户操作</span>
              <span v-if="item.status === 6">平台操作</span>
            </div>
          </template>
          <template slot="description">
            <div class="detail">{{item.detail}}</div>
            <div class="time">{{item.createTime}}</div>
          </template>
          <template slot="icon">
            <div :class="index===detail.recordList?.length-1 ? 'round' : 'round_min'" ></div>
          </template>
        </el-step>
      </el-steps>
    </div>

    <div class="flowList">
      <div class="title" style="padding-bottom: 10px;">服务进度</div>
      <ch-table :render-option="flowOption" :data="flowList" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:type="scope">
          <div>{{serveType[scope.row.type]||'一'}}</div>
        </template>
        <template v-slot:causeImg="scope">
          <div v-if="(scope.row.type===4||scope.row.type===5||scope.row.type===6)&&scope.row.cause" style="color: #3d7fff;cursor: pointer" @click="openImgView(scope.row.cause)">查看</div>
        </template>
      </ch-table>
    </div>
    <el-image-viewer
        v-if="showViewer"
        :z-index="9999"
        :on-close="closeViewer"
        :url-list="viewerList" />
  </div>


</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer.vue";

export default {
  components: {ElImageViewer},
  data() {
    return {
      detail:{},
      statusStrList:['','待处理','处理中','已撤诉','已处理','已完结','已完结'],
      serveType: ['一','预约','取消','修改','开始','待验收','已完成','已接单','更换服务人员', '申请改期', '同意改期','拒绝改期','签到'],
      fileList:[],
      flowList:[],
      viewerList:[],
      showViewer:false,
      id:null,
    }
  },
  activated() {
    if(this.$route.query.id){
      this.id = this.$route.query.id
      this.getDetail()
    }
  },
  computed:{
    flowOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: "text", label: "当前进度", prop: "detail",},
        {column: "slot", label: "订单状态", slotName: "type"},
        {column: "text", label: "操作时间", prop: "createTime",},
        {column: "slot", label: "服务图片", slotName: "causeImg",},
      ];
    },
  },
  methods: {
    getDetail(){
      this.$curl.post("/hm/hmGServiceOrderAppeal/detail/"+this.id).then(res=>{
        this.detail = res.data
        this.flowList = res.data.flowList
        if(res.data.imgUrl){
          this.fileList = res.data.imgUrl.split(',')
        }
      })
    },
    openImgView(data){
      if (Array.isArray(data)){
        this.viewerList = data
      }else{
        this.viewerList = data.split(',')
      }
      this.showViewer = true
    },
    closeViewer(){
      this.showViewer = false
    }
  }
}
</script>


<style scoped lang="scss">
.appeal_detail{
  background: #FFFFFF;
  padding: 30px;
  overflow: auto;
  margin-top: 20px;

  .content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
    .item{
      display: flex;
      align-items: center;
      .value{
        color: #666666;
        span{
          font-size: 12px;
          width: 34px;
          border-radius: 2px;
          border: 1px solid #CCCCCC;
          margin-left: 10px;
          padding: 3px 5px;
          color: #999999;
        }
      }
      .image{
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 5px;
      }
    }

  }

  .flowList{
    padding: 16px 0;
    .title{
      font-weight: bold;
      padding-bottom: 2px;
    }
    .flowList_warp{
      padding-bottom: 10px;
      .createTime{
        color: #999999;
        padding-top: 10px;
      }
    }

  }
  .steps{
    margin-top: 30px;
    padding: 10px 30px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .steps_title{
      font-weight: bold;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #E9E9E9;
    }
    .status{
      width: 200px;
      font-size: 16px;
      padding-bottom: 10px;
      color: rgba(0, 0, 0, 0.65);
      display: inline-block;
      .active{
        color: rgba(0, 0, 0, 0.85);
      }
      span{
        font-size: 12px;
        width: 34px;
        border-radius: 2px;
        border: 1px solid #CCCCCC;
        margin-left: 10px;
        padding: 3px 5px;
        color: #999999;
      }
    }
    .detail,
    .time{
      font-size: 14px;
      color: #00000072;
      padding-bottom: 10px;
    }
    .round_min{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #3d7fff;
    }
    .round{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #3d7fff;
    }
  }
}
::v-deep .el-step__icon.is-text{
  border: none !important;
}
</style>